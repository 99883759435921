import React from 'react';
import { API, Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import Gallery from "react-photo-gallery";
import {  FormGroup , Input,  Row, Col,Container, Button, Card, CardBody, CardText, Badge} from 'reactstrap';


const getPhotoObject = async (afterKey) =>{
    let img= new Image();
    img.src=await Storage.get(afterKey);
    return new Promise((resolve,reject) => {
        img.onload = function(){
            var height = img.height;
            var width = img.width;
            resolve({
                src: img.src,
                width,
                height,
                afterKey
            });
          }

    });

}

const SubmissonCard=({projectid, artist, nlikes, location, beforeObjectKey, userid, timestamp, afterObjectKey, description}) => {
    return (
    <Card>
        <CardBody>
            <Row>
                <Col sm={6}>
                <S3Image imgKey={beforeObjectKey} theme={{ photoImg: { width: '100%'} }} />
                </Col>
                <Col sm={6}>
                <S3Image imgKey={afterObjectKey} theme={{ photoImg: { width: '100%'} }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                <CardText style={{textAlign:'left'}}>
                  <span style={{fontWeight:'bold'}}>{(artist ? (artist.given_name+' '+artist.family_name+' ') :' ')}</span> <span>{description}</span> <Badge color="success" pill>{'+'+nlikes}</Badge> 
                </CardText>
                </Col>
            </Row>
            
        </CardBody>
      </Card>)
}



class Submissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            submissions: [], 
            photos:[],
            layout:'list',
            project:'',
            sortby: 'latest'
        };
        this.sortSubmissions = this.sortSubmissions.bind(this);
    }

    _handlePhotoClick(event,data)
    {
        console.log(data);
    }

    getSubmissions()
    {
        console.log(this.props);
        let apiName = 'Arki-API'
        let path = (this.props.isadmin ? '' :'/client')+'/submission/byproject/'+this.state.project;
            API.get(apiName, path).then(resp => {
                // Add your code here
                console.log(resp);
                let list=resp.data;
                this.setState({submissions: list});
                this.sortSubmissions();
                Promise.all(list.map((elem) => getPhotoObject(elem.afterObjectKey))).then(photos => this.setState({photos}));

            }).catch((error) => {
                alert(error.response.data.message);
                console.log(error.response);
            });

    }

    handleLayoutChange(event){
        this.setState({layout: event.target.value})
    }
    sortSubmissions() {
        this.setState((state) => ({submissions: state.submissions.sort((a, b) => {
            let bTime = (new Date(b.timestamp)).getTime();
            let aTime = (new Date(a.timestamp)).getTime();
            return ((this.state.sortby === 'popular') ? (b.nlikes - a.nlikes) : (bTime - aTime));
        }) }));
    }
    handleSortChange(event)
    {
        let newSort=event.target.value;
        this.setState({
                sortby: newSort
            });
        this.sortSubmissions();
        
    }

    componentDidMount() {


    }
    render() {
        return (
            <Container>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Input style={{textTransform: "uppercase"}} name="project" id="proj" placeholder="Project code" value={this.state.project} onChange={(event) => this.setState({project: event.target.value.toUpperCase()})} onKeyUp={(event)=>(event.keyCode === 13 ? this.getSubmissions() : null )}/>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                    <FormGroup>
                         <Input type="select" value={this.state.layout} onChange={this.handleLayoutChange.bind(this)}>
                            <option value="list">List</option>
                            <option value="grid">Grid</option>
                        </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                    <FormGroup>
                         <Input type="select"  value={this.state.sortby} onChange={this.handleSortChange.bind(this)}>
                            <option value="latest">Recent</option>
                            <option value="popular">Most liked</option>
                        </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                    <Button color="warning" block onClick={this.getSubmissions.bind(this)}>Get photos</Button>
                    </Col>
                </Row>
                {(this.state.layout === 'list') && this.state.submissions.map((sub,ind) => (<SubmissonCard key={ind} {...sub}/>))}

                {(this.state.layout === 'grid') && <Gallery photos={this.state.photos} onClick={this._handlePhotoClick}/>}
            </Container>
        );
    }
}

export default Submissions;