//In your react App.js or yourComponent.js file add these lines to import
import React, { Component } from 'react';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import '../Styles/overrides.css';
import {API} from 'aws-amplify';
import {Alert} from 'reactstrap';

const myCss = {
    "navigation": {
        "complete": "sv_complete_btn btn-lg SrvSubmitBt"
    }
};
export default class SurveyForm extends Component {
 //Define Survey JSON
 //Here is the simplest Survey with one text question
 /* 
  */

    preJson = {
        elements: [
            //{ type: "text", name: "customerName", title: "What is your name?", isRequired: true },
            {
                type: "radiogroup", name: "gender", title: "Please specify your gender", isRequired: true,
                choices: [
                    {
                        "value": "female",
                        "text": "Female"
                    },
                    {
                        "value": "male",
                        "text": "Male"
                    },
                    {
                        "value": "othergender",
                        "text": "Other"
                    }
                ]
            },
            {
                type: "radiogroup", name: "age", title: "How old are you?", isRequired: true,
                choices: [
                    {
                        "value": "bet15to25",
                        "text": "15-25"
                    },
                    {
                        "value": "bet25to45",
                        "text": "25-45"
                    },
                    {
                        "value": "bet45to65",
                        "text": "45-65"
                    },
                    {
                        "value": "over65",
                        "text": "Over 65"
                    }
                ]
            },
            {
                type: "radiogroup", name: "job", title: "Would you define yourself as:",  isRequired: true,
                choices: [
                    {
                        "value": "student",
                        "text": "Student"
                    },
                    {
                        "value": "localresident",
                        "text": "Local resident"
                    },
                    {
                        "value": "councilstaff",
                        "text": "Council staff"
                    },
                    {
                        "value": "urbanpractictioner",
                        "text": "Urban practictioner"
                    },
                    {
                        "value": "otherjob",
                        "text": "Other"
                    },
                ]
            },
            {
                type: "radiogroup", name: "howcontribute", title: "How should local residents contribute to local urban design projects?", isRequired: true,
                choices: [
                    {
                        "value": "nocontribution",
                        "text": "No contribution as they don’t have the knowledge."
                    },
                    {
                        "value": "commforums",
                        "text": "Community forums organised by project managers."
                    },
                    {
                        "value": "optionselecting",
                        "text": "Selecting between options proposed by urban designers"
                    },
                    {
                        "value": "participatdesign",
                        "text": "Participating in the design process itself"
                    }
                ]
            },
            {
                type: "radiogroup", name: "howlongphone", title: "How long do you spend on your phone per day?",  isRequired: true,
                choices: [
                    {
                        "value": "less2",
                        "text": "Less than 2 hours                                            "
                    },
                    {
                        "value": "betw2to4",
                        "text": "between 2 and 4 hours"
                    },
                    {
                        "value": "betw4-8",
                        "text": "between 4 and 8 hours"
                    },
                    {
                        "value": "more8",
                        "text": "more than 8 hours"
                    }
                ]
            },
            {
                type: "radiogroup", name: "howmanyapps", title: "How many mobile applications (Apps) are you regularly using on your phone?",  isRequired: true,
                choices: [
                    {
                        "value": "less2",
                        "text": "Less than 2"
                    },
                    {
                        "value": "bet2to5",
                        "text": "2 to 5"
                    },
                    {
                        "value": "bet5to10",
                        "text": "5 to 10"
                    },
                    {
                        "value": "more10",
                        "text": "more than 10"
                    }
                ]
            },
            {
                type: "radiogroup", name: "feeling", title: "How do you feel about joining this workshop?",  isRequired: true,
                choices: [
                    {
                        "value": "anxious",
                        "text": "Anxious"
                    },
                    {
                        "value": "unsure",
                        "text": "unsure"
                    },
                    {
                        "value": "ok",
                        "text": "ok"
                    },
                    {
                        "value": "amused",
                        "text": "amused"
                    },
                    {
                        "value": "exited",
                        "text": "excited"
                    },
                ]
            }
        ]
    };

    postJson=  {
        elements: [ 
            {
                "type": "matrix",
                "name": "yesnoMatrix1",
                "title": "Please answer the following questions regarding your experience with Arkicity:",
                isRequired: true,
                "columns": [
                 "Yes",
                 "No"
                ],
                "rows": [
                 "Was it easy to install and run Arkicity for the first time?",
                 "Did you found it difficult to navigate through Arkicity?",
                 "Were you lost navigating Arkicity at any point during the trial?",
                 "Did Arkicity respond well when you selected various options?",
                 "Were you frustrated at times when creating your collage?",
                 "Were you satisfied with your final collage?",
                 //"",
                 "Did Arkicity help you to express your design ideas?",
                 "Did Arkicity help you to share design ideas with others?",
                 "Would you like to use Arkicity more often?",
                 "Would you recommend Arkicity to others?"
                ]
               },
               {
                "type": "radiogroup",
                "name": "whichoption",
                "title": "Which design option did you prefer?",
                isRequired: true,
                choices: [
                    {
                        "value": "collage",
                        "text": "2D Collage"
                    },
                    {
                        "value": "AR",
                        "text": "3D Augmented Reality (AR)"
                    }
                ]
               },
               {
                "type": "comment",
                "name": "freetextfb",
                "title": "Please, could you suggest improvements to Arkicity?",
                "maxLength": 150
               }

        ]
    };


 //Define a callback methods on survey complete
    onComplete = (survey, options) => {
        //Write survey results into database
        console.log(this.props.userid);
        const path = '/survey/'+this.props.mode+'/' + this.props.userid,
         params= {
             body: {
                response: survey.data
             }
         };
        API.post('Arki-API', path,params).then(resp => {
            // Add your code here
            console.log(resp);
            options.showDataSavingSuccess();
        }).catch((error) => {
            console.log(error);
            options.showDataSavingError()
        });
    }
 render() {
  //Create the model and pass it into react Survey component
  //You may create survey model outside the render function and use it in your App or component
  //The most model properties are reactive, on their change the component will change UI when needed.
  console.log(this.props.userid);
  var model = new Survey.Model((this.props.mode === 'pre') ? this.preJson : this.postJson);
  return (this.props.userid ?  <Survey.Survey model={model} onComplete={this.onComplete} css={myCss} completeText="Submit" /> : <Alert color="danger">User ID missing</Alert>);
  /*
  //The alternative way. react Survey component will create survey model internally
  return (<Survey.Survey json={this.json} onComplete={this.onComplete}/>);
  */
  //You may pass model properties directly into component or set it into model
  // <Survey.Survey model={model} mode="display"/>
  //or 
  // model.mode="display"
  // <Survey.Survey model={model}/>
  // You may change model properties outside render function. 
  //If needed react Survey Component will change its behavior and change UI.
 }
} 