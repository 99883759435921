import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { withAuthenticator } from 'aws-amplify-react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Submissions from './components/Submissions'
import CollageCarousel from './components/Carousel'
import {Auth} from 'aws-amplify'
import SurveyForm from './components/Appsurvey'


class ProtectedApp extends React.Component {
  state = {
    isAdmin: false,
    userid:''
  }


  async componentWillMount(){
    const accessTokenPL=(await Auth.currentSession()).getAccessToken().decodePayload();
    console.log(accessTokenPL.sub);
    let groups=accessTokenPL['cognito:groups'];
    let isAdmin = Array.isArray(groups) && ( groups.includes("Admin"));

    this.setState({isAdmin, 'userid':accessTokenPL.sub });
  }

render() {
  let isAdmin=this.state.isAdmin;
  return (
    <Router>
      <Switch>
      <Route path="/" exact render={(props) =><Home {...props} isadmin={isAdmin}/>} />
      <Route
        path="/carousel/:projid"
        component={CollageCarousel}
      />
      <Route
            path="/survey/pre"
            render={(props) => <SurveyForm {...props} mode="pre" userid={this.state.userid} />}
          />
      <Route
            path="/survey/post"
            render={(props) => <SurveyForm {...props} mode="post" userid={this.state.userid} />}
          />
      </Switch>
  </Router>
);
}
}


function Home(props){
  return(<div className="App">
  <Submissions isadmin={props.isadmin}/>
</div>);
}

export default withAuthenticator(ProtectedApp, true);
//export default App;
