import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import ProtectedApp from './ProtectedApp'
import SurveyForm from './components/Appsurvey'

class App extends React.Component {
  state = {
  }



  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/survey/pre/:userid"
            render={(props) => <SurveyForm {...props} mode="pre" userid={props.match.params.userid} />}
          />
          <Route
            path="/survey/post/:userid"
            render={(props) => <SurveyForm {...props} mode="post" userid={props.match.params.userid} />}
          />
          <Route path="/" component={ProtectedApp} />
        </Switch>

      </Router>
    );
  }
}


// function Test(props) {
//   return (<div className="App">
//     <h1>Survey</h1>
//   </div>);
// }

export default App;
