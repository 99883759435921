import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Styles/overrides.css';  // change this to the file path of your overrides
import { Carousel } from 'react-responsive-carousel';
import { API  } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import {Badge , Container} from 'reactstrap';

const BeforeSubmissonCard = ({ projectid, artist, nlikes, location, beforeObjectKey, userid, timestamp, afterObjectKey, description }) => {
    return (
        <div>
            <S3Image imgKey={beforeObjectKey}  />
        </div>
    )
}
const AfterSubmissonCard = ({ projectid, artist, nlikes, location, beforeObjectKey, userid, timestamp, afterObjectKey, description }) => {
    return (
        <div>
            <S3Image imgKey={afterObjectKey}  />
            <p className="legend"><span style={{fontWeight:'bold'}}>{(artist ? (artist.given_name+' '+artist.family_name+' ') :' ')}</span> <span>{description}</span> <Badge color="success" pill>{'+'+nlikes}</Badge> </p>
        </div>
    )
}

const generateCarouselChildren = (submissions) => {
    let output = [];
    submissions.forEach((sub, ind) => {
        output.push(<BeforeSubmissonCard key={2*ind} {...sub} />);
        output.push(<AfterSubmissonCard key={2*ind+1} {...sub} />);
    });
    return output;
}

class CollageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submissions: [],
            project: props.match.params.projid,
            sortby: 'latest'
        };
        this.getSubmissions = this.getSubmissions.bind(this);
    }

    getSubmissions() {
        let apiName = 'Arki-API'
        let path = '/submission/byproject/' + this.state.project;
        API.get(apiName, path).then(resp => {
            // Add your code here
            //console.log(resp);
            let list = resp.data;
            this.setState({ submissions: list });
        }).catch(error => {
            console.log(error);
        });

    }
    componentDidMount() {
        this.getSubmissions();


    }

    render() {
        //console.log(this.props.match.params);
        return (
            <Container>
            <Carousel autoPlay={true} showThumbs={false} dynamicHeight={false} infiniteLoop={true}  showArrows={true} interval={5000} transitionTime={1}>
                {generateCarouselChildren(this.state.submissions)}
            </Carousel>
            </Container>
        );
    }
};

export default CollageCarousel;